<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="page">
      <!-- loading -->
      <css-loading v-show="loading" style="position: absolute;z-index: 9999"></css-loading>
      <!-- 顶部 -->
      <div class="img">
          <!-- <div class="image"></div> -->
          <div class="image" :style="{backgroundImage:'url('+avatarPath+')'}"></div>
          <div>
            <h3>{{nickname}}</h3>
            <span>账号：{{username}}</span>
          </div>
          <div class="signature">
            <span style="color: rgb(255, 255, 255);font-family: fangsong;"><span style="color: rgb(255, 255, 255) ;">个性签名:</span>{{ this.signature || '暂无' }}</span>
          </div>
          <div class="show">
            <div class="show-item" v-for="item,index in showList" :key="index">
              <span style="color: azure;">{{ item.label }}</span>
              <span style="color: azure;">{{ item.value }}</span>
            </div>
          </div>
      </div>
      <!-- 中部 -->
      <div class="center">
        <van-swipe class="my-swipe" :autoplay="9000" indicator-color="white">
          <van-swipe-item style="display: flex;justify-content: space-between;align-items: center;">
            <div class="charts" @click="onShowCharts('charts1')">
              <Charts1 :value="charts1.value"></Charts1>
            </div>
            <div class="charts" @click="onShowCharts('charts2')">
              <Charts2 :value="charts2.value"></Charts2>
            </div>
          </van-swipe-item>
          <van-swipe-item style="display: flex;justify-content: space-between;align-items: center;">
            <div class="charts" @click="onShowCharts('charts3')">
              <Charts3 :value="charts3.value"></Charts3>
            </div>
            <div class="charts" @click="onShowCharts('charts4')">
              <Charts4 :value="charts4.value"></Charts4>
            </div>
          </van-swipe-item>
        </van-swipe>

      </div>
      <!-- 底部 -->
      <div class="list">
        <div class="margin-set film-orders" @click="handleUserInfo">
          <div>
            <van-icon size="1.1111rem" color="#3e669d" name="manager-o" />
            <span>个人信息</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleMySendEmail">
          <div>
            <van-icon size="1.1111rem" color="#bfc769" name="envelop-o" />
            <span>已发邮件</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div v-if="userinfo.role=='admin'" class="margin-set film-orders" @click="handleAllMsg">
          <div>
            <van-icon size="1.1111rem" color="#bfc769" name="envelop-o" />
            <span>所有留言</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <!-- <div class="margin-set film-orders" @click="handleMyEmail">
          <div>
            <van-icon size="1.1111rem" color="#abcd1a" name="envelop-o" />
            <span>我的邮件</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleMyEmail">
          <div>
            <van-icon size="1.1111rem" color="#1f5469" name="envelop-o" />
            <span>我的公开信</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleMyEmail">
          <div>
            <van-icon size="1.1111rem" color="#654569" name="envelop-o" />
            <span>我的私信</span>
            </div>
          <van-icon name="arrow" />
        </div> -->
        <div v-if="userinfo.role=='admin'" class="margin-set film-orders" @click="handleVideo">
          <div>
            <van-icon size="1.1111rem" color="#8bb196" name="notes-o" />
            <span>视频</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div v-if="userinfo.role=='admin'" class="margin-set film-orders" @click="handleUserManage">
          <div>
            <van-icon size="1.1111rem" color="#8bb196" name="notes-o" />
            <span>用户管理</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleMemorandum">
          <div>
            <van-icon size="1.1111rem" color="#8bb196" name="notes-o" />
            <span>备忘录</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleXuexitong">
          <div>
            <van-icon size="1.1111rem" color="#286452" name="notes-o" />
            <span>学习通课程</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set film-orders" @click="handleWeightLoss">
          <div>
            <van-icon size="1.1111rem" color="#896196" name="notes-o" />
            <span>减肥计划</span>
            </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set setting" @click="handleSet">
          <div>
            <van-icon size="1.1111rem" color="green" name="setting-o" />
            <span>设置</span>
          </div>
          <van-icon name="arrow" />
        </div>
        <div class="margin-set setting" style="display: flex;justify-content: center;margin-bottom: 70px;" >
          <div>
            <span>
              <a href="https://www.sweek.top/api/preview/letter.apk" download style="color: dodgerblue;">
                点击我下载安装包
              </a>
            </span>
          </div>
        </div>
        <div class="margin-set setting logout" @click="logout">
          <div>
            <!-- <van-icon size="1.1111rem" color="#000" name="exchange" /> -->
            退出登录
          </div>
          <!-- <van-icon name="arrow" /> -->
        </div>
      </div>
      <!-- 弹窗 -->
      <MDialog :is-show="showCharts">
        <!-- 使用具名插槽 -->
        <template v-slot:content>
          <Charts1Detail v-if="chartsType==='charts1'"></Charts1Detail>
          <Charts2Detail v-if="chartsType==='charts2'"></Charts2Detail>
          <Charts3Detail v-if="chartsType==='charts3'"></Charts3Detail>
          <Charts4Detail v-if="chartsType==='charts4'"></Charts4Detail>
        </template>
        <!-- 使用具名插槽 -->
        <template v-slot:btn>
          <van-button @click="onConfirm">确认</van-button>
        </template>
      </MDialog>
  </div>
</template>
<script>
import Charts1 from './components/charts1.vue'
import Charts1Detail from './components/charts1Detail.vue'
import Charts2 from './components/charts2.vue'
import Charts2Detail from './components/charts2Detail.vue'
import Charts3 from './components/charts3.vue'
import Charts3Detail from './components/charts3Detail.vue'
import Charts4 from './components/charts4.vue'
import Charts4Detail from './components/charts4Detail.vue'
import MDialog from './components/dialog.vue'
import { Toast, Dialog } from 'vant'
export default {
  components: {
    Charts1,
    Charts1Detail,
    Charts2,
    Charts2Detail,
    Charts3,
    Charts3Detail,
    Charts4,
    Charts4Detail,
    MDialog
  },
  data () {
    return {
      chartsType: '',
      showCharts: false,
      charts4: {
        value: ['1200', '1000', '800', '1270', '1365', '1350']
      },
      charts3: {
        value: [
          { name: 'master', percent: '', total: '1000', finished: '400' },
          { name: 'Sweek', percent: '', total: '1000', finished: '300' },
          { name: '王二', percent: '', total: '1000', finished: '200' },
          { name: '李四', percent: '', total: '1000', finished: '30' },
          { name: '张三', percent: '', total: '1000', finished: '20' }
        ]
      },
      charts2: {
        value: 20
      },
      charts1: {
        value: 20
      },
      showList: [
        { label: '关注', value: 0 },
        { label: '粉丝', value: 0 },
        { label: '访客', value: 0 }
      ],
      loading: false,
      id: '',
      nickname: '',
      username: '',
      password: '',
      user: [],
      avatar: '',
      avatarPath: '',
      signature: ''
    }
  },
  computed: {
    userinfo () {
      return JSON.parse(localStorage.getItem('userinfo'))
    }
  },
  created () {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    console.log('userinfo：：：+ ', userinfo)
    this.username = userinfo.username
    this.nickname = userinfo.nickname
    this.signature = userinfo.signature
    this.avatarPath = 'https://www.sweek.top/api/preview/' + (userinfo.avatar || 'images-1686473319084-738752596.jpg')
  },
  mounted () {

  },
  methods: {
    // 弹窗确认按钮
    onConfirm () {
      this.showCharts = false
    },
    // 显示charts弹窗
    onShowCharts (type) {
      this.chartsType = type
      this.showCharts = true
    },
    // 跳转视频
    handleVideo () {
      this.$router.push({
        path: '/video'
      })
    },
    // 跳转用户管理
    handleUserManage () {
      this.$router.push({
        path: '/user-manage'
      })
    },
    // 跳转所有留言
    handleAllMsg () {
      this.$router.push({
        path: '/all-msg'
      })
    },
    // 跳转学习通课程
    handleXuexitong () {
      this.$router.push({
        path: '/xuexitong'
      })
    },
    // 跳转备忘录
    handleMemorandum () {
      this.$router.push({
        path: '/memorandum'
      })
    },
    // 跳转减肥计划
    handleWeightLoss () {
      this.$router.push({
        path: '/weightloss',
        query: {
          back: '1'
        }
      })
    },
    // 跳转个人信息页面
    handleUserInfo () {
      this.$router.push({
        path: '/user-info'
      })
    },
    // 跳转到我的已发邮件
    handleMyEmail () {
      Toast.loading({
        message: '开发中'
      })
    },
    // 跳转到我的已发邮件
    handleMySendEmail () {
      const email = JSON.parse(localStorage.getItem('userinfo')).email
      if (email === '1877288470@qq.com') {
        this.toSYYJ()
      } else {
        this.toYFYJ()
      }
    },
    // 跳转已发邮件页面
    toYFYJ () {
      this.$router.push({
        path: '/my-email'
      })
    },
    // 跳转所有邮件页面
    toSYYJ () {
      this.$router.push({
        path: '/all-email'
      })
    },
    handleSet () {
      this.$router.push({
        path: '/setting'
      })
    },
    logout () {
      Dialog.confirm({
        title: '提示',
        message: '确认退出登录吗'
      })
        .then(() => {
          // on confirm
          this.loading = true
          setTimeout(() => {
            this.loading = false
            // 处理退出登录的逻辑
            localStorage.removeItem('token')
            localStorage.removeItem('userinfo')
            this.$router.replace({
              path: '/login'
            })
            Toast({
              message: '退出登录成功',
              icon: 'success'
            })
          }, 1500)
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}

</script>
<style lang="scss" scoped>
.page{
  height: calc(100vh - 46px);
  width: 100vw;
  overflow: auto;
  background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  position: relative;
  box-sizing: border-box;
.img{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: linear-gradient(to bottom, #42773c, rgb(158, 201, 156));
  opacity: 0.9;
  width: calc(100vw - 20px);
  height: 200px;
  box-sizing: content-box;
  padding: 1.0111rem 0 0 0.2222rem;
  border-radius: 15px 15px 15px 15px;
  margin: 5px 10px;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(204, 204, 204);
  .image{
    width: 3.8889rem;
    height: 3.8889rem;
    border-radius: 15px;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    margin: 0 .5556rem;
  }
  h3{
    margin: 0 .8333rem;
    margin-top: 10px;
    margin-bottom: 5px;
    align-self: center;
    font-style: .8889rem;
    color: #ffffff;
    font-family: "NSimSun";
  }
  span{
    margin-top: 10px;
    margin: 0 .8333rem;
    align-self: center;
    font-style: .8889rem;
    font-size: 14px;
    color: #3d3d3d;
    font-family: 'KaiTi';
  }
  .signature {
    position: relative;
    bottom: 8px;
    left: -20px;
    width: 100vw;
  }
  .show {
    position: relative;
    bottom: 5px;
    left: 0px;
    width: 90vw;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    // background-color: rgb(88, 122, 109);
    background: linear-gradient(to top, #42773c, rgb(122, 167, 120));
    margin: 0 2.5vw;
    box-shadow: 0 0 4px rgb(207, 207, 207);
    .show-item {
      width: 30vw;
      height: 30px;
      line-height: 30px;
    }
    .show-item:active {
      background-color: rgb(113, 170, 142);
      border-radius: 5px;
    }
  }
}
.center {
  width: calc(100vw - 20px);
  margin:  10px 10px;
  height: 120px;
  // background-color: rgb(114, 167, 164);
  // border-radius: 10px;
  // box-shadow: 0 0 5px black;
  .charts {
    width: 46.3vw;
    height: 120px;
    // background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px rgb(165, 165, 165);
  }
}
.btn{
  text-align: center;
   .btn-left{
     margin:1.1111rem;
   }
   .btn-right{
     margin: 1.1111rem;
   }
}
.list{
  font-size: .8333rem;
  height: calc(100vh - 470px);
  width: calc(100vw - 20px);
  margin: 10px 10px;
  border-radius:10px;
  box-shadow: 0 0 5px rgb(193, 193, 193);
  background-color: rgb(239, 243, 242);
  padding-bottom: 10px;
  overflow-y: auto;
  font-family: fangsong;
  font-weight: bold;
  color: #646464;
  .margin-set {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin: 0 .9333rem;
    height: 2.8rem;
    border-radius: 0px;
    border-bottom: 1px solid rgb(197, 197, 197);
    span{
      position: relative;
      margin-left: .8333rem;
      top: -0.1667rem;
    }
  }
  .margin-set:active {
    background: linear-gradient(to right, #96d090, rgb(255, 255, 255));
  }
}
}

div{
  box-sizing: border-box;
}
.logout {
  background-color: rgb(216, 123, 123);
  // margin: 0!important;z
  margin: 0 3.9333rem!important;
  justify-content: center!important;
  border-radius: 10px!important;
  height: 2.4rem!important;
  line-height: 2.4rem!important;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(136, 136, 136);
}

  </style>
