<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="box">
      <!-- loading -->
      <css-loading v-show="loading" style="position: absolute;z-index: 9999"></css-loading>
      <div  class="card" :class="{ 'is-flipped': !isLogin }">
        <!-- 登录部分 -->
        <div v-if="isLogin" class="login card login-card">
          <van-tabs class="tab" v-model="loginType">
            <van-tab name="username">
              <template #title> <van-icon name="user-o" />账号登录</template>
            </van-tab>
            <van-tab name="email">
              <template #title> <van-icon name="envelop-o" />邮箱登录</template>
            </van-tab>
          </van-tabs>
          <!-- 通过账号登录 -->
          <van-form v-if="loginType==='username'" class="form" @submit="onLogin('username')">
            <van-cell-group>
              <van-field
              v-model="usernameForm.username"
              label="账号"
              placeholder="请输入账号"
              :rules="[{ required: true, message: '' }]"
            />
              <van-field
              v-model="usernameForm.password"
              type="password"
              label="密码"
              placeholder="请输入密码"
              autocomplete="off"
              :rules="[{ required: true, message: '' }]"
              />
            </van-cell-group>
            <div class="btn">
              <van-button class="login" size="large" native-type="submit"><van-icon name="certificate" />登录</van-button>
              <van-button class="reset" size="large" native-type="button" @click.native.prevent="onReset('username')"><van-icon name="replay" />重置</van-button>
              <div class="to-register"><van-icon name="info-o" /><span style="margin-left: 5px;border-bottom: 1px solid dodgerblue;" @click="toRegister">还没有账号，去注册</span></div>
            </div>
          </van-form>
          <!-- 通过邮箱登录 -->
          <van-form class="form" v-if="loginType==='email'" @submit="onLogin('email')">
            <van-cell-group>
            <van-field
              v-model="emailForm.email"
              label="邮箱"
              placeholder="请输入邮箱"
              :readonly="isVarify"
              :rules="[{ required: true, message: '' },{ type: 'email', message: '邮箱格式不正确' }]"
              >
                <template #button>
                  <van-button v-if="!isVarify" size="small" type="info" native-type="button" @click.native.prevent="onVarifyEmail('login')"><van-icon name="guide-o" />验证</van-button>
                  <van-button v-else size="small" type="primary" native-type="button"><van-icon name="success" />验证通过</van-button>
                </template>
              </van-field>
            </van-cell-group>
            <div class="btn">
              <van-button :disabled="!isVarify" class="login" size="large" native-type="submit"><van-icon name="certificate" />登录</van-button>
              <!-- <van-button class="reset" size="large" native-type="button" @click.native.prevent="onReset('email')"><van-icon name="replay" />重置</van-button> -->
              <div class="to-register"><van-icon name="info-o" /><span style="margin-left: 5px;border-bottom: 1px solid dodgerblue;" @click="toRegister">还没有账号，去注册</span></div>
            </div>
          </van-form>
        </div>
        <!-- 注册部分 -->
        <div v-else class="register card register-card">
          <van-tabs class="tab">
            <van-tab>
              <template #title>账号注册</template>
            </van-tab>
          </van-tabs>
          <van-form class="form" @submit="onRegister()">
            <van-cell-group>
              <van-field
                v-show="false"
                v-model="registerForm.nickname"
                label="昵称"
                placeholder="系统自动生成"
                readonly
              />
              <van-field
              v-model="registerForm.username"
              label="账号"
              placeholder="系统根据邮箱自动生成"
              readonly
            />
            <van-field
              v-model="registerForm.password"
              type="password"
              label="密码"
              placeholder="默认密码：12345678"
              readonly
              />
              <van-field
              v-model="registerForm.email"
              label="邮箱"
              placeholder="请输入邮箱"
              :readonly="isRegisterVarify"
              :rules="[{ required: true, message: '' },{ type: 'email', message: '邮箱格式不正确' }]"
              >
                <template #button>
                  <van-button v-if="!isRegisterVarify" size="small" type="info" native-type="button" @click.native.prevent="onVarifyEmail('register')"><van-icon name="guide-o" />验证</van-button>
                  <van-button v-else size="small" type="primary" native-type="button"><van-icon name="success" />验证通过</van-button>
                </template>
              </van-field>

            </van-cell-group>
            <div class="btn">
              <van-button v-if="!registerLoading" class="login" size="large" native-type="submit"><van-icon name="sign" />注册</van-button>
              <van-button v-else loading loading-text="注册中"/>
              <!-- <van-button class="reset" size="large" native-type="button" @click.native.prevent="onReset('register')"><van-icon name="replay" />重置</van-button> -->
              <div class="to-register"><van-icon name="info-o" /><span style="margin-left: 5px;border-bottom: 1px solid dodgerblue;" @click="toLogin">已有账号，去登录</span></div>
            </div>
          </van-form>
        </div>
      </div>
      <!-- 邮箱验证弹出框 -->
      <transition name="van-fade">
        <van-popup class="pop" v-model="showEmailPop" :close-on-click-overlay="false">
          <div class="pop-top">
            验证码已发至邮箱 <span style="color:rgb(182, 88, 88)">{{ varifyType==='login' ? emailForm.email : registerForm.email }}</span>，请注意查收
          </div>
          <div class="pop-form">
            <VerificationCodeInput v-if="varifyType==='login'" v-model="emailForm.code" @input="onVerificationCodeInput" />
            <VerificationCodeInput v-if="varifyType==='register'" v-model="registerForm.code" @input="onVerificationCodeInput" />
          </div>
          <div class="pop-btn">
            <van-button style="border: 1px solid #03a7fc;color: #03a7fc;" v-if="!reSubmitLoading" @click="() => reSubmitCode(varifyType)" :disabled="reSubmitDisabled"><van-icon name="replay"/>{{ buttonText }}</van-button>
            <van-button style="border: 1px solid #03a7fc;color: #03a7fc;" v-else loading loading-text="发送中"/>
            <van-button id="varify-btn" v-if="!varifyLoading" type="info" @click="onVarifyEmailPop"><van-icon name="guide-o" />验证</van-button>
            <van-button v-else loading type="info" loading-text="验证中"/>
          </div>
          <div class="pop-btn">
            <van-button style="border-radius: 15px;width: 80px;height: 45px;font-size: 22px;border: 1px solid gray;color: gray;" @click="() => showEmailPop = false"><van-icon name="cross" /></van-button>
          </div>
        </van-popup>
      </transition>
      <!-- logo -->
      <div class="logo">
        <van-image
          width="80px"
          height="80px"
          radius="5px"
          src="https://pic.imgdb.cn/item/65914930c458853aeffb8e9f.jpg"
        />
        <div style="text-align: center;font-size: 18px;font-family: kaiti;font-weight: bold;color: rgb(83, 111, 112);">
          留言信箱
        </div>
      </div>
    </div>
</template>
<script>
import { getUserInfo } from '@/api/user'
import { loginByEmail, loginByUsername, verifyEmailCode, registerUser, getEmailCode } from '@/api/login'
import VerificationCodeInput from '@/components/input/VerificationCodeInput.vue'
import { Button, Toast } from 'vant'

export default {
  components: {
    VerificationCodeInput
  },
  data () {
    return {
      // loading
      loading: false,
      // 验证弹窗处理类型
      varifyType: 'login',
      // 重新发送按钮禁用
      reSubmitDisabled: false,
      buttonText: '重新发送',
      remainingSeconds: 60,
      countdownTimer: null,
      // 注册按钮loading
      registerLoading: false,
      // 重新发送loading
      reSubmitLoading: false,
      // 邮箱验证码内弹出框验证loading
      varifyLoading: false,
      // 显示邮箱验证弹出框
      showEmailPop: false,
      // 控制邮箱是否验证通过
      // 登录
      isVarify: false,
      // 注册
      isRegisterVarify: false,
      // 控制登录和中祖册页面显示
      isLogin: true,
      loginType: 'email',
      registerForm: {
        email: '',
        code: '',
        username: '',
        nickname: '',
        password: ''
      },
      usernameForm: {
        username: '',
        password: ''
      },
      emailForm: {
        email: '',
        code: ''
      }
    }
  },
  watch: {
    isLogin (newVal, oldVal) {
      if (newVal === true) {
        this.varifyType = 'login'
      } else {
        this.varifyType = 'register'
      }
    }
  },
  methods: {
    validateEmail (email) {
      // 使用正则表达式验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
    // 验证输入框变化触发事件
    onVerificationCodeInput (value) {
      // console.log('value：：：+ ', value.length)
      if (value.length === 4) {
        setTimeout(() => {
          var varifyBtn = document.querySelector('#varify-btn')
          if (varifyBtn) {
            varifyBtn.click()
          }
        }, 1000)
      }
    },
    // 重置按钮触发事件
    onReset (type) {
      if (type === 'username') {
        this.usernameForm = {
          username: '',
          password: ''
        }
      } else if (type === 'email') {
        this.emailForm = {
          email: ''
        }
      } else if (type === 'register') {
        // this.$set(this.registerForm, 'code', '')
        // this.$set(this.registerForm, 'username', '')
        // this.$set(this.registerForm, 'nickname', '')
        // this.$set(this.registerForm, 'password', '')
      }
    },
    // 切换登录页面
    toLogin () {
      this.isLogin = true
    },
    // 切换注册页面
    toRegister () {
      this.isLogin = false
    },
    // 点击验证邮箱按钮
    onVarifyEmail (type) {
      this.varifyType = type
      if (type === 'login') {
        if (!this.validateEmail(this.emailForm.email)) {
          return Toast({
            message: '邮箱格式不正确',
            icon: 'fail'
          })
        }
        // 验证邮箱是否已注册
        const url = 'https://www.sweek.top/api/checkEmailExists'
        const data = {
          email: this.emailForm.email
        }
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // 声明请求主体的内容类型为 JSON
          },
          body: JSON.stringify(data) // 将数据对象转换为 JSON 字符串并作为请求主体
        }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json() // 解析 JSON 响应数据
        }).then(data => {
          // console.log('Response:', data);
          // console.log('data：：：+ ', data)
          if (data.exists) {
            this.showEmailPop = true
            this.reSubmitCode(type)
          } else {
            Toast({
              message: '邮箱还未注册，请先前往注册！',
              icon: 'fail'
            })
            this.emailForm.email = ''
          }
        }).catch(error => {
          console.error('Error:', error)
        })
      } else if (type === 'register') {
        // 验证邮箱格式
        if (!this.validateEmail(this.registerForm.email)) {
          return Toast({
            message: '邮箱格式不正确',
            icon: 'fail'
          })
        }
        // 验证邮箱是否已注册
        const url = 'https://www.sweek.top/api/checkEmailExists'
        const data = {
          email: this.registerForm.email
        }
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json' // 声明请求主体的内容类型为 JSON
          },
          body: JSON.stringify(data) // 将数据对象转换为 JSON 字符串并作为请求主体
        }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json() // 解析 JSON 响应数据
        }).then(data => {
          // console.log('Response:', data);
          // console.log('data：：：+ ', data)
          if (data.exists) {
            Toast({
              message: '邮箱已被注册！',
              icon: 'fail'
            })
            this.registerForm.email = ''
          } else {
            this.showEmailPop = true
            this.reSubmitCode(type)
          }
        }).catch(error => {
          console.error('Error:', error)
        })
      }
    },
    // 弹出框内确认验证按钮
    onVarifyEmailPop () {
      if (this.varifyType === 'login') {
        this.varifyLoading = true
        const { email, code } = this.emailForm
        // console.log('this.registerForm：：：+ ', this.registerForm)
        verifyEmailCode({ email, code }).then(res => {
        // console.log('res：：：+ ', res)
          Toast({
            message: '验证成功',
            icon: 'success'
          })
          this.showEmailPop = false
          this.isVarify = true
        }).finally(() => {
          this.varifyLoading = false
        })
      } else if (this.varifyType === 'register') {
        this.varifyLoading = true
        const { email, code } = this.registerForm
        // console.log('this.registerForm：：：+ ', this.registerForm)
        verifyEmailCode({ email, code }).then(res => {
        // console.log('res：：：+ ', res)
          Toast({
            message: '验证成功',
            icon: 'success'
          })
          this.showEmailPop = false
          this.isRegisterVarify = true
        }).finally(() => {
          this.varifyLoading = false
        })
      }
    },
    // 重新发送按钮点击触发事件
    reSubmitCode (type) {
      if (type === 'login') {
        this.reSubmitLoading = true
        const { email } = this.emailForm
        getEmailCode({ email }).then(res => {
          Toast({
            message: '验证码发送成功',
            icon: 'success'
          })
          this.reSubmitDisabled = true
          this.remainingSeconds = 60
          this.buttonText = `重新发送 (${this.remainingSeconds}s)`
          this.countdownTimer = setInterval(() => {
            this.remainingSeconds--
            if (this.remainingSeconds > 0) {
              this.buttonText = `重新发送 (${this.remainingSeconds}s)`
            } else {
              this.reSubmitDisabled = false
              this.buttonText = '重新发送'
              clearInterval(this.countdownTimer)
            }
          }, 1000)
          // 在这里添加触发重新发送验证码的逻辑
        }).finally(() => {
          this.reSubmitLoading = false
        })
      } else if (type === 'register') {
        this.reSubmitLoading = true
        const { email } = this.registerForm
        getEmailCode({ email }).then(res => {
          Toast({
            message: '验证码发送成功',
            icon: 'success'
          })
          this.reSubmitDisabled = true
          this.remainingSeconds = 60
          this.buttonText = `重新发送 (${this.remainingSeconds}s)`
          this.countdownTimer = setInterval(() => {
            this.remainingSeconds--
            if (this.remainingSeconds > 0) {
              this.buttonText = `重新发送 (${this.remainingSeconds}s)`
            } else {
              this.reSubmitDisabled = false
              this.buttonText = '重新发送'
              clearInterval(this.countdownTimer)
            }
          }, 1000)
          // 在这里添加触发重新发送验证码的逻辑
        }).finally(() => {
          this.reSubmitLoading = false
        })
      }
    },
    // 点击登录 发送请求
    async onLogin (type) {
      if (type === 'username') {
        this.loading = true
        try {
          const res = await loginByUsername(this.usernameForm)
          // console.log('res：：：+ ', res)
          await this.getUserInfo('username')
          setTimeout(() => {
            this.loading = false
            this.$router.replace({
              path: '/mineCenter'
            })
            Toast({
              message: '登录成功',
              icon: 'success'
            })
          }, 1500)
        } catch (e) {
          console.log('e：：：+ ', e)
          this.loading = false
          // 可以根据实际情况添加错误处理逻辑
        }
      } else if (type === 'email') {
        if (!this.validateEmail(this.emailForm.email)) {
          return Toast({
            message: '邮箱格式不正确',
            icon: 'fail'
          })
        }
        this.loading = true
        try {
          const res = await loginByEmail(this.emailForm)
          // console.log('res：：：+ ', res)
          await this.getUserInfo('email')
          setTimeout(() => {
            this.loading = false
            this.$router.replace({
              path: '/mineCenter'
            })
            Toast({
              message: '登录成功',
              icon: 'success'
            })
          }, 1500)
        } catch (error) {
          // 可以根据实际情况添加错误处理逻辑
          console.error(error)
          this.loading = false
        }
      }
    },
    // 点击注册 发送请求
    async onRegister () {
      this.registerLoading = true
      try {
        const res = await registerUser(this.registerForm)
        Toast({
          message: '注册成功',
          icon: 'success'
        })
        /* 新的逻辑--注册成功之后自动登录直接进入程序 */
        await this.getUserInfo('register')
        Toast.loading({
          message: '登录中'
        })
        setTimeout(() => {
          Toast.clear()
          this.$router.replace({
            path: '/mineCenter'
          })
          Toast({
            message: '登录成功',
            icon: 'success'
          })
        }, 1500)
      } catch (error) {
        // 可以根据实际情况添加错误处理逻辑
        console.error(error)
      } finally {
        this.registerLoading = false
      }

      /* 旧的逻辑--注册成功之后参数带入，再登录 */
      // this.isLogin = true
      // const { email, username, password, code } = this.registerForm
      // this.usernameForm = { username, password }
      // this.emailForm = { email, code }
    },
    // 获取当前登录账号信息，存入内存
    async getUserInfo (type) {
      if (type === 'username') {
        const res = await getUserInfo({
          username: this.usernameForm.username
        })
        // console.log('res：：：+ ', res)
        const token = 'your_token_value'
        localStorage.setItem('token', token)
        const userinfo = JSON.stringify(res)
        localStorage.setItem('userinfo', userinfo)
      } else if (type === 'email') {
        const res = await getUserInfo({
          email: this.emailForm.email
        })
        // console.log('res：：：+ ', res)
        const token = 'your_token_value'
        localStorage.setItem('token', token)
        const userinfo = JSON.stringify(res)
        localStorage.setItem('userinfo', userinfo)
      } else if (type === 'register') {
        const res = await getUserInfo({
          email: this.registerForm.email
        })
        // console.log('res：：：+ ', res)
        const token = 'your_token_value'
        localStorage.setItem('token', token)
        const userinfo = JSON.stringify(res)
        localStorage.setItem('userinfo', userinfo)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  width: 80px;
  height: 120px;
  position: absolute;
  top: 50px;
  left: calc(50vw - 40px);
}
.box{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  background-image: url('../../assets/login-background.jpg');
  background-size: cover; /* 让背景铺满整个容器 */
  height: calc(100vh);
}
.login{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 320px;
  background-color: rgb(194, 214, 207);
  border-radius: 10px;
  flex-wrap: wrap;
  // box-shadow: 0 0 3px rgb(65, 87, 72);
  padding: 0 5px 0 5px;
  .tab{
    width: 100%;
    height:30px;
    ::v-deep {
      .van-tabs__line {
        position: absolute;
        bottom: 15px;
        left: 0;
        z-index: 1;
        width: 40px;
        height: 3px;
        background-color: #6b8b71;
        border-radius: 3px;
      }
      .van-tab--active {
        color: #369473!important;
        font-weight: bold;
      }
      .van-tab {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 4px;
        color: #646566;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        background-color: rgb(194, 214, 207);
      }
    }
  }
  .form{
    width: 100%;
    height: 30vh;
    ::v-deep {
      .van-button--small {
        height: 24px;
        padding: 0 8px;
        font-size: 12px;
      }
      .van-field__label {
        flex: none;
        box-sizing: border-box;
        width: 3.5em;
        margin-right: 12px;
        color: #415c42;
        text-align: left;
        word-wrap: break-word;
      }
      .van-cell-group {
        background-color: #7ea7b0;
        border-radius: 5px;
      }
      .van-cell {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        color: #323233;
        font-size: 14px;
        line-height: 24px;
        background-color: #7ea7b0;
        border-radius: 5px;
      }
      .van-field__control {
        display: block;
        box-sizing: border-box;
        width: 100%;
        min-width: 0;
        margin: 0;
        padding: 0;
        color: #254b3e;
        line-height: inherit;
        text-align: left;
        background-color: transparent;
        border: 0;
        resize: none;
        border-radius: 2px;
      }
    }
    .btn{
      text-align: center;
      .login {
        background-color: rgb(54, 194, 159);
        color: #3e3f3f;
        font-size: 14px;
        border: none;
      }
      .reset {
        background-color: rgb(226, 238, 236);
        color: #426459;
        font-size: .5rem;

      }
      .to-register {
        color: dodgerblue;
        font-size: .7rem;
        margin-top: 20px;
      }
      ::v-deep {
        .van-button--large {
          width: 100%;
          height: 40px;
          border-radius: 5px;
          margin-top: 15px;
        }
        .van-button {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 1.2;
          text-align: center;
          cursor: pointer;
          -webkit-transition: opacity .2s;
          transition: opacity .2s;
          border: 1px solid rgb(194, 214, 207);
        }
      }
    }
  }
}
.register{
  display: flex;
  justify-content: center;
  // align-items: center;
  box-sizing: border-box;
  height: 40vh;
  width: 320px;
  background-color: rgb(194, 214, 207);
  border-radius: 10px;
  flex-wrap: wrap;
  // box-shadow: 0 0 3px rgb(65, 87, 72);
  padding: 0px 5px 0 5px;
  .tab{
    width: 100%;
    height:30px;
    ::v-deep {
      .van-tabs__line {
        position: absolute;
        bottom: 15px;
        left: 0;
        z-index: 1;
        width: 40px;
        height: 3px;
        background-color: #6b8b71;
        border-radius: 3px;
      }
      .van-tab--active {
        color: #369473!important;
        font-weight: bold;
      }
      .van-tab {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 4px;
        color: #646566;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        background-color: rgb(194, 214, 207);
      }
    }
  }
  .form{
    width: 100%;
    height: 30vh;
    ::v-deep {
      .van-button--small {
        height: 24px;
        padding: 0 8px;
        font-size: 12px;
      }
      .van-field__label {
        flex: none;
        box-sizing: border-box;
        width: 3.5em;
        margin-right: 12px;
        color: #415c42;
        text-align: left;
        word-wrap: break-word;
      }
      .van-cell-group {
        background-color: #7ea7b0;
        border-radius: 5px;
      }
      .van-cell {
        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 16px;
        overflow: hidden;
        color: #323233;
        font-size: 14px;
        line-height: 24px;
        background-color: #7ea7b0;
        border-radius: 5px;
      }
      .van-field__control {
        display: block;
        box-sizing: border-box;
        width: 100%;
        min-width: 0;
        margin: 0;
        padding: 0;
        color: #254b3e;
        line-height: inherit;
        text-align: left;
        background-color: transparent;
        border: 0;
        resize: none;
        border-radius: 2px;
      }
    }
    .btn{
      text-align: center;
      .login {
        background-color: rgb(54, 194, 159);
        color: #3e3f3f;
        font-size: 14px;
      }
      .reset {
        background-color: rgb(226, 238, 236);
        color: #426459;
        font-size: .5rem;

      }
      .to-register {
        color: dodgerblue;
        font-size: .7rem;
        margin-top: 20px;
      }
      ::v-deep {
        .van-button--large {
          width: 100%;
          height: 40px;
          border-radius: 5px;
          margin-top: 15px;
        }
        .van-button {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 1.2;
          text-align: center;
          cursor: pointer;
          -webkit-transition: opacity .2s;
          transition: opacity .2s;
          border: 1px solid rgb(194, 214, 207);
        }
      }
    }
  }
}
.pop {
  height: 340px;
  width: 90vw;
  border-radius: 10px;
  background-color: rgb(186, 225, 237);
  .pop-top {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #4d4d4d;
    text-align: center;
    font-family: 'fangsong';
    font-weight: bold;
  }
  .pop-form {
    width: calc(100% - 20px);
    margin-left: 10px;
    height: 120px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .pop-btn {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ::v-deep {
      .van-button {
        height: 45px;
        width: 125px;
        padding: 0 8px;
        font-size: 16px;
        border-radius: 5px;
      }
    }
  }
}
.card {
  width: 80vw;
  height: 40vh;
  position: relative;
  transform-style: preserve-3d; /* 保留3D变换效果 */
  transition: transform 1.2s; /* 添加过渡效果，使翻转更平滑 */
}

.login-card {
  transform: rotateY(0deg); /* 初始状态正面朝上 */
}
.register-card {
  transform: rotateY(180deg); /* 初始状态背面朝上 */
}
.login-card,
.register-card {
  // position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* 隐藏卡片的背面内容 */
}
.is-flipped {
  transform: rotateY(180deg); /* 当卡片被翻转时应用旋转变换 */
}
</style>
