<template>
  <div class="user-manage">
    <!-- 顶部筛选 -->
    <CommonSearch
      v-model="queryParams.keyword"
      :placeholder="'请输入关键词查询，共' + total + '个用户'"
      @clickSearch="onRefresh"
      :showBtn="true"
      @clickBtn="onClickButton"
    ></CommonSearch>
    <!-- 用户列表 -->
    <div v-if="showType==='list'" class="sj-list" ref="scrollRef">
      <ViewList
        v-model="listLoading"
        :finished="finished"
        :errorReq="requestError"
        :refreshing="refreshing"
        :isEmpty="isEmpty"
        @loadData="getProjectList"
        @onRefresh="onRefresh"
        @changeRefresh="changeRefresh"
      >
        <UserItem
          v-for="(item,idx) in list"
          :key="idx"
          :item="item"
          @toEdit="toEdit(item)"
        />
      </ViewList>
    </div>
    <div v-else class="sj-list" ref="scrollRef">
      <ViewList
        v-model="listLoading"
        :finished="finished"
        :errorReq="requestError"
        :refreshing="refreshing"
        :isEmpty="isEmpty"
        @loadData="getProjectList"
        @onRefresh="onRefresh"
        @changeRefresh="changeRefresh"
      >
        <ve-table
          class="tab-veTable"
          style="width: 100%; word-break: break-all"
          :scroll-width="600"
          :columns="pureColumns"
          :table-data="list"
          :row-style-option="rowStyleOption"
          :border-x="true"
          :border-y="true"
          :fixed-header="true"
        />
      </ViewList>
      <van-empty
        v-show="list.length == 0 && !listLoading"
        description="暂无数据"
      />
    </div>
    <!-- 显示修改弹窗 -->
    <van-popup v-model="showEdit" class="pop" @close="closeEdit" :close-on-click-overlay="false">
      <div class="pop-title">修改信息</div>
      <div class="pop-top">
        <van-cell-group>
          <van-field v-model="editForm.nickname" label="昵称" placeholder="" />
          <van-field v-model="editForm.username" label="账号" placeholder="" />
          <van-field v-model="editForm.email" label="邮箱" placeholder="" />
          <van-field v-model="editForm.password" label="密码" placeholder="" />
          <van-field v-model="editForm.phone" label="手机" placeholder="" />
          <van-field v-model="editForm.sex" label="性别" placeholder="" />
          <van-field v-model="editForm.address" label="地址" placeholder="" />
          <van-field v-model="editForm.addressdetail" label="详细地址" placeholder="" />
        </van-cell-group>
      </div>
      <div class="pop-btn">
        <van-button type="info" @click="closeEdit">关闭</van-button>
        <van-button type="info" @click="confirmEdit">修改</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getUsersList, updateUserInfo } from '@/api/user.js'
import { handleDate } from '@/util/timeUtils'
import CommonSearch from '@/components/CommonSearch'
import ViewList from '@/components/ViewList'
import UserItem from './module/userItem.vue'
import { Toast } from 'vant'
export default {
  name: 'MyEmail',
  components: {
    CommonSearch,
    ViewList,
    UserItem
  },
  data () {
    return {
      total: 0,
      pureColumns: [
        {
          title: '昵称',
          field: 'nickname',
          key: 'nickname',
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text">{row[column.field]}</span>
          }
        },
        // {
        //   title: '账号',
        //   field: 'username',
        //   key: 'username',
        //   width: 80,
        //   renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     return <span class="text">{row[column.field]}</span>
        //   }
        // },
        {
          title: '邮箱',
          field: 'email',
          key: 'email',
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text">{row[column.field]}</span>
          }
        },
        // {
        //   title: '密码',
        //   field: 'password',
        //   key: 'password',
        //   width: 80,
        //   renderBodyCell: ({ row, column, rowIndex }, h) => {
        //     return <span class="text">{row[column.field]}</span>
        //   }
        // },
        {
          title: '创建时间',
          field: 'created_at',
          key: 'created_at',
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text">{row[column.field]}</span>
          }
        },
        {
          title: '修改时间',
          field: 'updated_at',
          key: 'updated_at',
          width: 80,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class="text">{row[column.field]}</span>
          }
        },
        {
          title: '操作',
          field: 'set',
          key: 'set',
          width: 40,
          fixed: 'right',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span class='set-text' onClick={(e) => this.toEdit(row)}><van-icon name="edit" />修改</span>
          }
        }
      ],
      pureData: [],
      rowStyleOption: {
        stripe: true
      },
      editForm: {
        nickname: null,
        username: null,
        email: null,
        password: null,
        phone: null,
        address: null,
        addressdetail: null,
        sex: null
      },
      showEdit: false,
      queryParams: {
        pageSize: 15,
        pageNum: 0,
        keyword: '',
        email: ''
      },
      // 刷新
      refreshing: false,
      isEmpty: false,
      finished: false,
      listLoading: false,
      requestError: false,
      // 弹窗数据
      showType: 'list',
      // 列表数据
      list: []
    }
  },
  computed: {
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.scrollRef.scrollTop = this.$route.meta.scrollTop
      if (this.needRefresh) {
        // this.$store.commit('bussiness/SET_NEED_REFRESH', false)
        this.onRefresh()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    from.meta.scrollTop = this.$refs.scrollRef.scrollTop
    next()
  },
  mounted () {
    this.getProjectList()
  },
  methods: {
    confirmEdit () {
      updateUserInfo(this.editForm).then(res => {
        this.showEdit = false
        Toast.success('修改成功')
        this.onRefresh()
      })
    },
    toEdit (item) {
      this.showEdit = true
      Object.keys(this.editForm).forEach(citem => {
        this.editForm[citem] = item[citem] || null
      })
    },
    // 关闭修改窗口
    closeEdit () {
      this.showEdit = false
    },
    // 监听子组件是否刷新
    changeRefresh (val) {
      this.refreshing = val
    },
    // 刷新
    onRefresh () {
      this.finished = false
      this.queryParams.pageNum = 0
      this.queryParams.pageSize = 15
      this.list = []
      this.getProjectList()
    },
    // 时间格式化
    formatISOTime (isoString) {
      const date = new Date(isoString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
    // 获取列表数据
    getProjectList () {
      this.isEmpty = false
      this.listLoading = true
      // 每次请求pageNum + 1
      this.queryParams.pageNum += 1
      // 处理请求参数
      const params = {}
      Object.keys(this.queryParams).forEach(k => {
        const val = this.queryParams[k]
        if (val !== null && val !== '') {
          params[k] = val
        }
      })
      // 如果上拉刷线,列表置空
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      getUsersList(params).then(res => {
        if (this.queryParams.pageNum === 1) {
          this.list = []
        }
        this.isEmpty = res.total === 0
        this.total = res.total
        res.result.forEach(item => {
          item.created_at = this.formatISOTime(item.created_at)
          item.updated_at = this.formatISOTime(item.updated_at)
        })
        const arr = res.result
        if (
          res.total <=
            this.queryParams.pageSize * this.queryParams.pageNum
        ) { this.finished = true }
        this.list = this.list.concat(arr)
        this.refreshing = false
        this.listLoading = false
      }).catch(() => {
        this.refreshing = false
        this.requestError = true
      }).finally(() => {
        this.listLoading = false
      })
    },
    // 切换
    onClickButton () {
      console.log('click：：：+ ')
      if (this.showType === 'table') {
        this.showType = 'list'
      } else {
        this.showType = 'table'
      }
    },
    // 回显时间
    calcTime (date) {
      return handleDate(date)
    },
    /* 跳转到详情 */
    toDetail (item) {
      this.$router.push({
        path: '/yfyj-detail',
        query: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.push-state {
  color: #4456fb !important;
  font-weight: bold;
}
.user-manage {
  .sj-list {
    height: calc(100vh - 100px);
    // height: calc(100vh - 150px);
    overflow: auto;
    padding-top: 8px;
    background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  }
  ::v-deep {
    .van-search__content--round {
      border-radius: 5px;
    }
    .van-search {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 12px;
      background-color: #b6d3b2;
    }
  }
}
.pop {
  height: 500px;
  width: 90vw;
  border-radius: 15px;
  background-color: rgb(240, 255, 244);
  .pop-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: gray;
    font-weight: bold;
    text-align: center;
  }
  .pop-top {
    width: 100%;
    height: 400px;
    line-height: 25px;
    font-size: 14px;
    color: gray;
    // padding: 10px;
    overflow-y: auto;
    font-family: fangsong;
    border: 1px solid gray;
    border-radius: 15px;
  }
  .pop-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ::v-deep {
      .van-button {
        height: 40px;
        width: 105px;
        padding: 0 8px;
        font-size: 16px;
        border-radius: 10px;
        background-color: #a4c7b3;
        border: 1px solid rgb(157, 197, 170);
      }
    }
  }
}

.user-manage ::v-deep .ve-table {
  .set-text {
    color: dodgerblue!important;
    font-size: 12px;
  }
  .text {
    font-size: 13px;
  }
}
</style>
