<!-- 邮件详情 -->
<!-- 邮件详情 -->
<template>
  <div class="main-page">
    <!-- loading -->
    <css-loading v-show="loading" style="position: absolute;z-index: 9999;"></css-loading>
    <!-- 拜邮件详情  -->
    <div class="item">
      <div class="item-title">
        <!-- <div class="icon"></div> -->
        <div class="text">邮件详情</div>
      </div>
      <div class="item-content">
        <div class="item-inner" v-for="item in basicInfo" :key="item.value">
          <div class="item-left">{{ item.label }}</div>
          <div class="item-right">{{ basicForm[item.value] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  name: 'CustomVistiDetail',
  data () {
    return {
      // loading
      loading: false,
      // 基本信息列表
      image: '',
      basicForm: {
        receive_email: '',
        text: ''
      },
      basicInfo: [
        {
          label: '昵称',
          value: 'name'
        },
        {
          label: '留言邮箱',
          value: 'email'
        },
        {
          label: '时间',
          value: 'date'
        },
        {
          label: '地区',
          value: 'location'
        },
        {
          label: '内容',
          value: 'text'
        }
      ]
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      this.getFormData()
      this.loading = false
    }, 1000)
  },
  methods: {
    imagePreview () {
      const img = []
      img.push(this.image)
      ImagePreview(img)
    },
    getFormData () {
      const data = this.$route.query
      this.basicInfo.forEach(item => {
        this.$set(this.basicForm, item.value, data[item.value] || '')
      })
      this.image = data.filename != '' ? 'https://www.sweek.top/api/preview/' + data.filename : null
    }
  }
}
</script>

<style lang="scss" scoped>
.main-page {
  width: 100%;
  height: calc(100vh - 46px);
  background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  overflow-y: auto;

  .item {
    width: calc(100vw - 20px);
    margin-left: 10px;
    height: auto;
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;

    .item-title {
      height: 32px;
      width: 100%;
      display: flex;
      padding-top: 6px;
      padding-bottom: 6px;
      margin-bottom: 8px;
      background-color: #ffffff;
      color: #4e993f;
      font-size: 12px;
      font-weight: 600;
      border-bottom: 1px solid rgb(177, 177, 177);

      .icon {
        width: 4px;
        height: 12px;
        margin: 3px 0 0 12px;
        background: #03a7fc;
        opacity: 1;
        border-radius: 3px;
      }

      .text {
        flex: 1;
        font-size: 14px;
        // font-weight: 600;
        margin-left: 10px;
      }
    }

    .item-content {
      width: 100%;
      height: auto;

      .lx-last {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .last-title {
          width: 88%;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          // font-weight: 600;
          color: rgb(55, 55, 55);
        }

        .last-content {
          width: 88%;
          min-height: 40px;
          line-height: 40px;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          color: gray;
          margin-bottom: 10px;

          .content-left {
            width: 48%;
            background-color: #F3F6FE;
            border-radius: 6px;
            .icon {
              display: inline-flex;
              align-items: center;
              /* 垂直居中 */
              gap: 1px;
              /* 图标和文本之间的间距 */
            }
            .icon img {
              width: 15px;
              /* 图标的宽度 */
              height: 15px;
              /* 图标的高度 */
              margin-right: 2px;
              margin-left: 2px;
              /* 图标和文本之间的间距 */
            }
          }
          .content-right {
            width: 50%;
            background-color: #FDF9F3;
            border-radius: 6px;
            .icon {
              display: inline-flex;
              align-items: center;
              /* 垂直居中 */
              gap: 1px;
              /* 图标和文本之间的间距 */
            }
            .icon img {
              width: 15px;
              /* 图标的宽度 */
              height: 15px;
              /* 图标的高度 */
              margin-right: 2px;
              margin-left: 2px;
              /* 图标和文本之间的间距 */
            }
          }
        }
      }

      .cj-last {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .last-title {
          width: 95%;
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-weight: 600;
          color: rgb(55, 55, 55);
        }

        .last-content {
          width: 95%;
          min-height: 40px;
          line-height: 40px;
          font-size: 15px;
          display: flex;
          background-color: #EDEFF3;
          border-radius: 6px;
          color: gray;
          margin-bottom: 10px;
          flex-wrap: wrap;

          .content-left {
            width: 50%;
            padding-left: 10px;
          }

          .content-right {
            width: 50%;
          }
        }
      }

      .item-inner {
        width: 100%;
        min-height: 35px;
        display: flex;
        justify-content: center;
        background-color: #ffffff;

        .item-left {
          width: 20%;
          min-height: 20px;
          font-size: 14px;
          color: gray;
          // line-height: 35px;
          padding-left: 5px;
          margin-bottom: 15px;
        }

        .item-right {
          width: 78%;
          min-height: 20px;
          font-size: 14px;
          line-height: 25px;
          margin-bottom: 15px;
          overflow-wrap: break-word;
          border: 1px solid rgb(207, 207, 207);
          border-radius: 5px;
          padding: 0 5px;
          background-color: #fff;
          font-family: fangsong;
        }
      }

      .item-inner-last {
        width: 100%;
        min-height: 35px;
        display: flex;
        justify-content: center;
        background-color: #ffffff;

        .item-left {
          width: 26%;
          min-height: 20px;
          font-size: 14px;
          color: gray;
          // line-height: 35px;
          padding-left: 18px;
          margin-bottom: 15px;
        }

        .item-right {
          width: 70%;
          min-height: 20px;
          font-size: 14px;
          // line-height: 35px;
          margin-bottom: 15px;
          overflow-wrap: break-word;
        }
      }

      .item-inner-half {
        width: 100%;
        min-height: 35px;
        display: flex;
        justify-content: center;
        background-color: #ffffff;

        .item-left {
          width: 50%;
          min-height: 20px;
          font-size: 14px;
          color: gray;
          // line-height: 35px;
          padding-left: 18px;
          margin-bottom: 15px;

          .text {
            color: black;
          }
        }

        .item-right {
          width: 46%;
          min-height: 20px;
          font-size: 14px;
          color: rgb(0, 0, 0);
          // line-height: 35px;
          margin-bottom: 15px;
          overflow-wrap: break-word;

          .text {
            color: black;
          }
        }
      }
    }

    .process-step {
      background-color: #fff;
      width: 100vw;
      overflow: auto;
      height: 65px;
      margin-bottom: 5px;

      :deep(.van-steps) {
        width: 100%;
        overflow: auto;
      }

      // :deep(.van-steps__items){
      //   width: 100%;
      // }
      :deep(.van-steps .van-steps__items .van-step) {
        flex-shrink: 0;
        flex-basis: auto;
        width: 100px;
      }

      :deep(.van-steps .van-steps__items .van-step:last-child) {
        position: inherit;

        .van-step__title {
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        .van-step__circle-container {
          right: inherit;
          left: -8px;
        }
      }
    }
  }
}
</style>
